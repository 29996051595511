import { Cookies } from '@adtech/cookies';
import { Loader } from '@adtech/ui';
import { redirectConfig } from '@configs/authRedirectRules';
import authTypes from '@constants/authTypes';
import cookieName from '@constants/cookieName';
import reportsConstants from '@constants/reports';
import { useAppDispatch } from '@hooks/useStore';
import { userActions } from '@redux/slices/users';
import { IRootSlice } from '@typings/rootSlice';
import { getRegistrationUrl, getLoginUrl, getDomain } from '@utils/auth';
import React, { useEffect, useRef } from 'react';
import { ConnectedComponent, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const LoaderComp: React.FC = () => <Loader loading />;

interface IOption {
    Component: React.FC | ConnectedComponent<any, any>;
    isPrivate?: boolean;
}

const AuthDecorator = ({ Component, isPrivate = false }: IOption) => {
    const dispatch = useAppDispatch();
    const params: IUrlParams = useParams();
    const isFirstRender = useRef(true);
    const timer = useRef(null);

    const { host, href, origin } = window.location;

    let isRequest = useSelector((state: IRootSlice) => state.userSlice.userRequest);
    const userId = useSelector((state: IRootSlice) => state.userSlice.id);
    const isAuth = !!userId;

    // Рефрешим токен, чтобы авторизация в кк не отвалилась, а флаги юзера были актуальными
    useEffect(() => {
        if (isAuth) {
            timer.current = setInterval(() => dispatch(userActions.refreshToken()), 180000);
        }
        return () => clearInterval(timer.current);
    }, [isAuth]);

    // Временно
    function getHash(str) {
        const FIRST_CONST = 4294967295; // 11111111111111111111111111111111 | 1 x 32 | 0 x 0
        const SECOND_CONST = 4278190080; // 11111111000000000000000000000000 | 1 x 8  | 0 x 24
        const UNITS = 8 - 1; // units count - 1
        const ZEROS = 24; // zeros count
        const DIFF = ZEROS - UNITS - 1; // zeros and units diff - 1
        let hash = 1;
        let code = 0;

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < str.length; i++) {
            code = str.charCodeAt(i);
            // eslint-disable-next-line no-bitwise
            hash = ((hash << UNITS) & FIRST_CONST) + (code << DIFF) + code;
            // eslint-disable-next-line no-bitwise
            code = hash & SECOND_CONST;

            if (code !== 0) {
                // eslint-disable-next-line no-bitwise
                hash ^= code >> ZEROS;
            }
        }

        return hash;
    }

    function generatePubId(ver) {
        const str =
            window.navigator.userAgent +
            document.cookie +
            document.referrer +
            window.innerWidth +
            window.innerHeight +
            Math.random().toString(36).substr(2, 9);
        const hashStr = getHash(str);
        const date = +new Date();
        return [ver, hashStr, date].join('.');
    }

    function sendCounterPixel(eventName) {
        const random = Math.random().toString(10).substr(2, 15);
        let pubId = Cookies.get('stat_pubId');
        if (!pubId) {
            pubId = generatePubId('stat2');
            Cookies.set('stat_pubId', pubId, {
                maxAge: 60 * 60 * 24 * 365,
                path: '/',
                domain: getDomain(),
            });
        }

        const img = new Image();
        img.src = `https://kraken.rambler.ru/cnt/v2/?project_id=6680988&counter_type=pixel&event_id=${random}&session_id=${random}&request_id=${random}&event_type=cv&event_name=${eventName}&publisher_uid_scope=stat.top100.rambler.ru&publisher_uid=${pubId}`;
    }
    // Временно

    // Запрос на первый рендер, useEffect асинхронный, не используем
    if (!isAuth && !isRequest && isFirstRender.current) {
        isFirstRender.current = false;
        isRequest = true;
        dispatch(userActions.getUser());
    }

    if (isRequest) return <LoaderComp />;

    if (
        isAuth ||
        !isPrivate ||
        // Открываем доступ к Дашборду и отчётам для Демо-сайта
        params?.projectId === String(reportsConstants.DEMO_PROJECT_ID)
    ) {
        return <Component />;
    }

    // Не авторизован - делаем редирект на систему авторизации в зависимости от домена
    const currentDomain = Object.keys(redirectConfig).find((domain) => host.includes(domain));
    let authType = redirectConfig[currentDomain] || authTypes.KEYCLOAK;

    if (authType === authTypes.RSID) {
        sendCounterPixel('rambler_login');
        window?.top100Counter.trackEvent('rambler_login_counter');
    }

    if (authType === authTypes.RSID && !href.includes('login')) {
        authType = authTypes.KEYCLOAK;
    }

    Cookies.set(cookieName.authType, authType, {
        maxAge: 60 * 60 * 24 * 365,
        path: '/',
        domain: getDomain(),
    });

    let redirectUrl: string;
    if (authType === authTypes.RSID) {
        redirectUrl = getLoginUrl(href, getRegistrationUrl(origin));
        sendCounterPixel('rambler_force');
        window?.top100Counter.trackEvent('rambler_force_counter');
    } else {
        redirectUrl = `/api/v1.0/user/keycloak_auth?backUrl=${encodeURIComponent(origin)}/stat/projects`;
    }

    // фикс для firefox
    setTimeout(() => {
        window.location.href = redirectUrl;
    }, 100);

    return <LoaderComp />;
};

export default AuthDecorator;
