import { MultilevelMenu } from '@adtech/ui';
import attributesData from '@configs/attributesData';
import mediaConfig from '@configs/media';
import { navigationConfig, navigationStateStorageKey } from '@configs/navigation';
import useGlobalParams from '@hooks/useGlobalParams';
import { useRestrictAccessByEmail } from '@hooks/useRestrictAccessByEmail';
import { INavigationItem } from '@typings/reports';
import { IRootSlice } from '@typings/rootSlice';
import { urlUtils, storagesUtils } from '@utils/index';
import cn from 'classnames';
import React, { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import s from './Navigation.pcss';
import navigationUtils from './utils';

interface IProps {
    type?: 'report' | 'projects' | 'settings';
}

const Navigation: React.FC<IProps> = ({ type = 'report' }) => {
    const { pathname } = useLocation();
    const params: IUrlParams = useParams();
    const projectId = Number(params.projectId);
    const { setIsNavigationOpen } = useGlobalParams();
    const userId = useSelector(({ userSlice }: IRootSlice) => userSlice.id);
    const availableReports = useSelector(({ projectsSlice }: IRootSlice) => projectsSlice.current.available_reports);
    const isTop100Staff = useSelector(({ userSlice }: IRootSlice) => userSlice.isTop100Staff);
    const rolePermissions = useSelector(({ userSlice }: IRootSlice) => userSlice.rolePermissions);
    const config: INavigationItem[] = navigationConfig[type];
    const isNavigationOpen = useSelector((state: IRootSlice) => state.globalSlice.isNavigationOpen);
    const [isOpen, setIsOpen] = useState(
        storagesUtils.get(navigationStateStorageKey, userId) !== null
            ? !!storagesUtils.get(navigationStateStorageKey, userId)
            : isNavigationOpen,
    );

    // TODO: нужно будет разобраться с аналитикой блоков
    // const newreportAttr = attributesData.newreport;
    // const navSearchAttr = attributesData.newreport.navSearch;
    const navAttr = attributesData.newreport.navAttr;
    let nameSelectedItem = navigationUtils.getActive(pathname, projectId);
    let parentsSelectedItem = [];

    const getLink = (item) => urlUtils.generateUrl(projectId, item);

    const { isAccessRestricted, reports } = useRestrictAccessByEmail();

    const isReportAccess = (name: string): boolean => {
        // В availableReports для медиа приходят названия ручек, а не отчётов
        // проверяем доступность по главной ручке media_totals
        if (mediaConfig[name]) {
            return availableReports.includes('media_totals');
        }

        return [...availableReports, 'rating'].includes(name);
    };

    // Атрибут для автотестов
    const getAttributeForTests = (level?: number) => ['firstTopic', 'secondTopic', 'thirdTopic'][level - 1];

    const renderTitle = (item, menuLevel?: number): ReactNode => (
        <span className={s.menuTitle} data-testid={getAttributeForTests(menuLevel)}>
            {item.title}
        </span>
    );

    const renderLink = (item, menuLevel?: number): ReactNode => {
        const { title, name } = item;

        return (
            <Link
                data-testid={getAttributeForTests(menuLevel)}
                data-newreport={`${navAttr}::${name}_btn`}
                to={getLink(item)}
            >
                {title}
            </Link>
        );
    };

    const checkAndFilterItems = (
        items,
        menuLevel?: number,
        parentIcon?: ReactNode,
    ): {
        checkedItems;
        activeItem: string;
    } => {
        let active = '';
        return {
            checkedItems: items
                .map((item) => {
                    const hasChildren = item.children;
                    const { title, name } = item;
                    const icon = item.icon ?? parentIcon;
                    let result = {};

                    // Ограничение доступа к отчетам по email на основе конфига
                    if (isAccessRestricted && reports?.includes(name)) return null;

                    if (item.isTop100StaffOnly && !isTop100Staff && !rolePermissions.can_read_dev_reports) return null;

                    // Если навигация по отчетам, проверяем доступен ли отчет
                    if (type === 'report' && !hasChildren && !isReportAccess(name)) return null;

                    result = {
                        label: hasChildren ? renderTitle(item, menuLevel) : renderLink(item, menuLevel),
                        title,
                        icon,
                        key: name,
                    };

                    // Проверям на активный элемент
                    if (nameSelectedItem === name) {
                        active = name;
                    }

                    // Проверяем на дочерний материал, при котором в навигации
                    // активным должен выделяется родитель (напр, materials_summary и material)
                    if (nameSelectedItem === item?.hiddenChild) {
                        nameSelectedItem = name;
                        active = name;
                    }

                    // Если у элемента есть дочерние элементы, проверяем их
                    if (hasChildren) {
                        const { checkedItems, activeItem } = checkAndFilterItems(item.children, menuLevel + 1, icon);

                        if (activeItem) {
                            parentsSelectedItem = [...parentsSelectedItem, activeItem];
                            active = item.name;
                        }

                        // Если нет доступных дочерних элементов, скрываем раздел
                        if (!checkedItems.length) return null;

                        return {
                            ...result,
                            type: 'submenu',
                            children: checkedItems,
                        };
                    }

                    return result;
                })
                .filter((el) => el),
            activeItem: active,
        };
    };

    const { checkedItems: navigationItems, activeItem: rootParentSelectedItem } = checkAndFilterItems(config, 1);

    // Состояние навигации при изменении записывается в store и localStorage
    const handleCollapse = (isOpened: boolean) => {
        setIsNavigationOpen(isOpened);
        setIsOpen(isOpened);
        storagesUtils.set(navigationStateStorageKey, isOpened ? 1 : 0, userId);
    };

    if (!navigationItems.length || !rootParentSelectedItem) return null;

    // Массив с ключами (name в config) родителей выбранного пункта навигации
    const defaultOpenKeys = [...new Set(parentsSelectedItem), rootParentSelectedItem];

    return (
        <div className={cn(s.root, isOpen ? undefined : s.menuClosed)}>
            <MultilevelMenu
                data-testid="menu-container"
                items={navigationItems}
                defaultSelectedKeys={[nameSelectedItem]}
                defaultOpenKeys={defaultOpenKeys}
                isMenuOpen={isOpen}
                onCollapse={handleCollapse}
                isDesktopOnly
            />
        </div>
    );
};

export default Navigation;
